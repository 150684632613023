import { useState } from "react"
import { ConsumerConnectionBillingStatusType, MergedDeviceType } from "@/types"
import { Search } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"

interface SearchDialogProps {
  devices: MergedDeviceType[]
  onDeviceSelect: (device: MergedDeviceType) => void
}

export function SearchDialog({ devices, onDeviceSelect }: SearchDialogProps) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button
        variant="outline"
        className="relative h-9 w-9 p-0 md:h-12 md:w-80 md:justify-start md:px-3 md:py-2"
        onClick={() => setOpen(true)}
      >
        <Search className="h-4 w-4 xl:mr-2" aria-hidden="true" />
        <span className="hidden xl:inline-flex">Search devices...</span>
        <span className="sr-only">Search devices</span>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type device name or ID to search..." />
        <CommandList>
          <CommandEmpty>No devices found.</CommandEmpty>
          <CommandGroup heading="Devices">
            {devices.map((device) => (
              <CommandItem
                key={device.device_id}
                onSelect={() => {
                  onDeviceSelect(device)
                  setOpen(false)
                }}
              >
                {`${device.full_name} - ${device.device_id}`}
              </CommandItem>
            ))}
          </CommandGroup>
        </CommandList>
      </CommandDialog>
    </>
  )
}
