import { onValue, ref, set } from "firebase/database"

import { database } from "../../firebase"

// Type for location data
export interface Location {
  latitude: number
  longitude: number
}

// Save location to Firebase
export const saveLocationToFirebase = async (
  userId: string,
  location: Location,
): Promise<void> => {
  try {
    const locationRef = ref(database, `users/${userId}/location`)
    await set(locationRef, location)
    console.log("Location saved to Firebase:", location)
  } catch (error) {
    console.error("Error saving location to Firebase:", error)
    throw error
  }
}

// Listen for location updates from Firebase
export const listenToLocationUpdates = (
  userId: string,
  callback: (location: Location) => void,
): void => {
  const locationRef = ref(database, `users/${userId}/location`)
  onValue(locationRef, (snapshot) => {
    const location = snapshot.val()
    if (location) {
      callback(location)
    } else {
      console.warn(`No location data found for user: ${userId}`)
    }
  })
}
