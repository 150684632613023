import { DeviceFromApiType } from "@/types"

import { apiFetch } from "../utils"

export async function getAllUserDevices(): Promise<DeviceFromApiType[]> {
  const response = await apiFetch("himo-woms/get-all-user-device")
  if (!response.ok) {
    throw new Error(`Error fetching: ${response.statusText}`)
  }
  return response.json()
}
