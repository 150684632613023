import * as React from "react"
import type { MergedDeviceType } from "@/types"
import { Home, Mail, MapPin, Phone, User } from "lucide-react"

import { Separator } from "@/components/ui/separator"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet"
import { Icons } from "@/components/icons"

interface TrackerIconProps {
  device: MergedDeviceType
  selected: boolean
}

interface UserDetailsSheetProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  device: MergedDeviceType
}

const statusColors: { [key: string]: string } = {
  Active: "#28a745",
  Selected: "#ffc107",
}

const getSolidColor = (selected: boolean) => {
  return selected ? statusColors.Selected : statusColors.Active
}

export function TrackerIcon({ device, selected }: TrackerIconProps) {
  const [isOpen, setIsOpen] = React.useState(false)
  const color = getSolidColor(selected)

  return (
    <>
      <Icons.user onClick={() => setIsOpen(true)} fill={color} />
      <UserDetailsSheet isOpen={isOpen} setIsOpen={setIsOpen} device={device} />
    </>
  )
}

export function UserDetailsSheet({
  isOpen,
  setIsOpen,
  device,
}: UserDetailsSheetProps) {
  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent className="sm:max-w-md">
        <SheetHeader>
          <SheetTitle className="text-2xl font-bold">
            {device.full_name ?? "Unknown User"}
          </SheetTitle>
        </SheetHeader>
        <div className="mt-6 space-y-6">
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-primary">
              Personal Information
            </h3>
            <Separator />
            <div className="grid grid-cols-[24px_1fr] items-center gap-x-2 gap-y-4">
              <Mail className="h-5 w-5 text-muted-foreground" />
              <p>{device.email ?? "N/A"}</p>
              <Phone className="h-5 w-5 text-muted-foreground" />
              <p>{device.mobile_number ?? "N/A"}</p>
              <Home className="h-5 w-5 text-muted-foreground" />
              <p>{device.address ?? "N/A"}</p>
              <User className="h-5 w-5 text-muted-foreground" />
              <p>{device.gender ?? "N/A"}</p>
            </div>
          </div>
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-primary">
              Device Information
            </h3>
            <Separator />
            <p>
              <span className="font-medium">Device ID:</span>{" "}
              {device.device_id ?? "Unknown"}
            </p>
          </div>
          <div className="space-y-2">
            <h3 className="text-lg font-semibold text-primary">
              Location Information
            </h3>
            <Separator />
            <div className="space-y-2 rounded-lg bg-muted p-4">
              {device.latitude != null && device.longitude != null ? (
                <div className="flex items-center space-x-2">
                  <MapPin className="h-5 w-5 text-muted-foreground" />
                  <p className="font-medium">
                    {device.latitude.toFixed(6)}, {device.longitude.toFixed(6)}
                  </p>
                </div>
              ) : (
                <p>Location data not available</p>
              )}
              {device.timestamp && (
                <p>
                  <span className="font-medium">Last Updated:</span>{" "}
                  {new Date(device.timestamp).toLocaleString()}
                </p>
              )}
              {device.accuracy != null && (
                <p>
                  <span className="font-medium">Accuracy:</span>{" "}
                  {device.accuracy.toFixed(2)} meters
                </p>
              )}
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  )
}
