import { useEffect, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "@tanstack/react-router"
import Cookies from "js-cookie"
import { Eye, EyeOff } from "lucide-react"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { systemConfig } from "@/config/site"
import {
  authenticate,
  deviceLogin,
  disableUser,
  sendMfaOtp,
} from "@/lib/api/auth"
import { getUserEmail, getUserId, isMfaEnabled } from "@/lib/auth"
import {
  Location,
  requestPermissions,
  trackBackgroundLocation,
  trackForegroundLocation,
} from "@/lib/location"
import { loginSchema } from "@/lib/schema/login"
import { useMediaQuery } from "@/hooks/use-media-query"
import { useToast } from "@/hooks/use-toast"
import { Button } from "@/components/ui/button"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Icons } from "@/components/icons"

export function LoginForm() {
  const { toast } = useToast()
  const navigate = useNavigate({ from: "/login" })
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<string | null>(null)
  const isDesktop = useMediaQuery("(min-width: 768px)")
  const [locationError, setLocationError] = useState<string | null>(null)

  useEffect(() => {
    const userId = getUserId()

    const initializePermissionsAndLocation = async () => {
      try {
        await requestPermissions()
        trackForegroundLocation(
          userId,
          (location: Location) => {
            console.log("Foreground location:", JSON.stringify(location))
          },
          (error) => {
            setLocationError(error.message)
          },
        )
        await trackBackgroundLocation(userId)
      } catch (error: any) {
        setLocationError(error.message)
      }
    }

    initializePermissionsAndLocation()
  }, [])

  const form = useForm<z.infer<typeof loginSchema>>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: "",
      password: "",
      system_id: systemConfig.hirayaSystemId,
    },
  })

  const toggleVisibility = () => setIsVisible((prevState) => !prevState)

  const loginAttempts = Cookies.get("loginAttempts")
    ? parseInt(Cookies.get("loginAttempts") || "0")
    : 0

  const nextAttemptTime = Cookies.get("nextAttemptTime")
    ? parseInt(Cookies.get("nextAttemptTime") || "0")
    : 0

  const mutation = useMutation({
    mutationFn: async (data: z.infer<typeof loginSchema>) => {
      await authenticate(data)
    },
    onSuccess: async () => {
      try {
        const platform: "web" | "mobile" = isDesktop ? "web" : "mobile"
        const response = await deviceLogin(platform)
        const email = getUserEmail()

        // Reset cookies upon successful login
        Cookies.remove("loginAttempts")
        Cookies.remove("nextAttemptTime")
        setLoginError(null) // Clear any existing errors

        if (response.ok) {
          if (isMfaEnabled()) {
            const mfaAuth = Cookies.get(`mfa-auth-${email}`)
            if (mfaAuth === "true") {
              navigate({ to: "/" })
            } else {
              sendMfaOtp()
              navigate({ to: "/mfa-auth" })
            }
          } else {
            navigate({ to: "/" })
          }
        } else {
          navigate({ to: "/unknown-device" })
        }
      } catch (error) {
        navigate({ to: "/unknown-device" })
      }
    },
    onError: (error) => {
      toast({
        title: "Something went wrong!",
        description: error.message,
        variant: "destructive",
      })
      if (loginAttempts >= 4) {
        setLoginError("Your account has been disabled. Please contact support.")
        disableUser(form.getValues().username) // Disable user on the 5th failed attempt

        // Reset login attempts after disabling account
        Cookies.remove("loginAttempts")
        Cookies.remove("nextAttemptTime")
      } else {
        let errorMsg = "Login failed. Please try again."
        let nextLockTime = 0

        if (loginAttempts >= 2) {
          nextLockTime = Date.now() + 5 * 60 * 1000 // 5 mins
          Cookies.set("nextAttemptTime", nextLockTime.toString())
          errorMsg =
            "Too many attempts! Please wait 5 minutes before trying again."
        } else if (loginAttempts >= 3) {
          nextLockTime = Date.now() + 10 * 60 * 1000 // 10 mins
          Cookies.set("nextAttemptTime", nextLockTime.toString())
          errorMsg =
            "Too many attempts! Please wait 10 minutes before trying again."
        }

        // Increment failed attempts and store in cookies
        Cookies.set("loginAttempts", (loginAttempts + 1).toString())
        setLoginError(errorMsg)
      }
    },
  })

  async function onSubmit(values: z.infer<typeof loginSchema>) {
    if (nextAttemptTime && Date.now() < nextAttemptTime) {
      const waitTime = Math.ceil((nextAttemptTime - Date.now()) / 1000 / 60)
      setLoginError(
        `Please wait ${waitTime} more minute(s) before trying again.`,
      )
      return
    }

    mutation.mutate(values)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex w-[20rem] flex-col gap-4"
      >
        <FormField
          control={form.control}
          name="username"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder="Username"
                  className="rounded-full bg-white text-black"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div className="relative">
                  <Input
                    className="rounded-full bg-white pe-9 text-black"
                    placeholder="Password"
                    type={isVisible ? "text" : "password"}
                    {...field}
                  />
                  <button
                    className="absolute inset-y-0 end-0 flex h-full w-9 items-center justify-center rounded-e-lg text-muted-foreground/80 ring-offset-background transition-shadow hover:text-black focus-visible:border focus-visible:border-ring focus-visible:text-black focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring/30 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50"
                    type="button"
                    onClick={toggleVisibility}
                    aria-label={isVisible ? "Hide password" : "Show password"}
                    aria-pressed={isVisible}
                    aria-controls="password"
                  >
                    {isVisible ? (
                      <EyeOff size={16} strokeWidth={2} aria-hidden="true" />
                    ) : (
                      <Eye size={16} strokeWidth={2} aria-hidden="true" />
                    )}
                  </button>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="items-top flex space-x-2">
          <Checkbox id="rememberMe" />
          <div className="grid gap-1.5 leading-none">
            <label
              htmlFor="rememberMe"
              className="text-sm font-medium leading-none text-black peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Remember Me
            </label>
          </div>
        </div>
        <Button
          type="submit"
          disabled={mutation.isPending}
          className="rounded-full"
        >
          {mutation.isPending && <Icons.loader className="mr-2 animate-spin" />}
          <span>Login</span>
        </Button>
        {loginError && (
          <p className="mt-2 text-center text-destructive">{loginError}</p>
        )}
      </form>
    </Form>
  )
}
