import { BackgroundRunner } from "@capacitor/background-runner"
import { Geolocation } from "@capacitor/geolocation"

import { saveLocationToFirebase } from "@/lib/firebase/location"

export interface Location {
  latitude: number
  longitude: number
  accuracy?: number
  altitude?: number | null
  altitudeAccuracy?: number | null
  speed?: number | null
  heading?: number | null
  timestamp: number
}

// Request permissions for both geolocation and notifications
export const requestPermissions = async (): Promise<void> => {
  try {
    // Request Geolocation permissions
    const geolocationPermStatus = await Geolocation.requestPermissions()
    if (geolocationPermStatus.location === "denied") {
      throw new Error("Location permissions denied.")
    }
    console.log("Location permissions granted.")

    // Request Notifications permissions
    const notificationsPermStatus = await BackgroundRunner.requestPermissions({
      apis: ["notifications", "geolocation"],
    })
    console.log("Notifications permissions granted: ", notificationsPermStatus)
  } catch (error) {
    console.error("Error requesting permissions: ", error)
    throw error
  }
}

// Track location in the foreground
export const trackForegroundLocation = (
  userId: string,
  onLocationUpdate: (location: Location) => void,
  onError: (error: GeolocationPositionError) => void,
): void => {
  Geolocation.watchPosition(
    {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 10000,
    },
    async (position, err) => {
      if (err) {
        console.error("Foreground location error: ", err)
        onError(err)
        return
      }

      if (position && position.coords) {
        const currentLocation: Location = {
          ...position.coords,
          timestamp: Date.now(),
        }
        onLocationUpdate(currentLocation)

        try {
          await saveLocationToFirebase(userId, currentLocation)
          console.log(
            "Foreground location saved to Firebase: ",
            currentLocation,
          )
        } catch (saveError) {
          console.error(
            "Error saving foreground location to Firebase: ",
            saveError,
          )
        }
      }
    },
  )
}

// Background location tracking
export const trackBackgroundLocation = async (
  userId: string,
): Promise<void> => {
  const result: any = await BackgroundRunner.dispatchEvent({
    label: "ai.himoreadandbill.hirayatech.background",
    event: "geoLocation",
    details: {},
  })

  console.log("Background location result: ", JSON.stringify(result))

  try {
    const currentLocation: Location = {
      ...result,
      timestamp: Date.now(),
    }

    await saveLocationToFirebase(userId, currentLocation)
    console.log("Background location saved to Firebase: ", currentLocation)
  } catch (error) {
    console.error("Error saving background location to Firebase: ", error)
  }
}
