import { useEffect, useState } from "react"
import { database } from "@/firebase"
import type {
  DeviceFromApiType,
  DeviceFromFirebaseType,
  MergedDeviceType,
} from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"
import { onValue, ref } from "firebase/database"

import { getAllUserDevices } from "@/lib/api/location"
import { Loader } from "@/components/loader"
import { MapRenderer } from "@/components/map/map-renderer"
import { SearchDialog } from "@/components/search-dialog"

interface UsersDataType {
  [userId: string]: {
    devices: Record<string, DeviceFromFirebaseType>
  }
}

export const Route = createFileRoute("/_home/tracking")({
  component: MapPage,
})

function MapPage() {
  const [markers, setMarkers] = useState<MergedDeviceType[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedLocation, setSelectedLocation] = useState<
    [number, number] | null
  >(null)

  const { data: userDevices } = useQuery({
    queryKey: ["userDevices"],
    queryFn: getAllUserDevices,
  })

  useEffect(() => {
    const usersRef = ref(database, "users")
    const unsubscribe = onValue(
      usersRef,
      (snapshot) => {
        const rawUsers = snapshot.val() as UsersDataType

        const usersData = Object.entries(rawUsers)
          .filter(([key]) => key !== "undefined")
          .reduce((acc, [key, value]) => {
            if (value !== null && value !== undefined) {
              acc[key] = value
            }
            return acc
          }, {} as UsersDataType)

        const mergedDevices: MergedDeviceType[] = []

        if (usersData && userDevices) {
          const apiDevices = userDevices.filter((device) => device.is_active)

          const apiDeviceIds = new Set(
            apiDevices.map((device) => device.device_id),
          )

          Object.entries(usersData).forEach(([userId, user]) => {
            if (user.devices) {
              const devices = user.devices

              Object.entries(devices).forEach(([deviceId, device]) => {
                if (apiDeviceIds.has(deviceId)) {
                  const matchingApiDevice = apiDevices.find(
                    (apiDevice) => apiDevice.device_id === deviceId,
                  )

                  if (matchingApiDevice) {
                    mergedDevices.push({
                      ...matchingApiDevice,
                      ...device,
                    })
                  }
                }
              })
            }
          })
        }

        setMarkers(mergedDevices)
        setIsLoading(false)
      },
      (error) => {
        console.error("Firebase error:", error)
        setIsLoading(false)
      },
    )

    return () => unsubscribe()
  }, [userDevices])

  const handleDeviceSelect = (device: MergedDeviceType) => {
    setSelectedLocation([device.latitude, device.longitude])
  }

  return (
    <main className="relative">
      {isLoading ? (
        <div className="flex h-screen items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="relative">
            <MapRenderer
              trackMarkers={markers}
              selectedLocation={selectedLocation}
            />
            <div className="absolute left-10 top-0 z-[500] p-2 md:p-4">
              <SearchDialog
                devices={markers}
                onDeviceSelect={handleDeviceSelect}
              />
            </div>
          </div>
        </>
      )}
    </main>
  )
}
