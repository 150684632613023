import {
  ArrowDownWideNarrow,
  ArrowUpDown,
  Bell,
  Book,
  Calendar,
  CheckCircle,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsUpDown,
  ChevronUp,
  CircleMinus,
  CircleUser,
  Clock,
  Command,
  CreditCard,
  Download,
  Edit,
  Ellipsis,
  FileScan,
  Filter,
  Gauge,
  Home,
  Key,
  Layers,
  LineChart,
  LoaderCircle,
  LogOut,
  Logs,
  Mail,
  Map,
  MapPin,
  Menu,
  Moon,
  Notebook,
  NotebookText,
  NotepadText,
  Package,
  Package2,
  PanelsTopLeft,
  Phone,
  Plus,
  Search,
  Settings,
  ShoppingCart,
  Smartphone,
  Square,
  SunMedium,
  Trash,
  User,
  UserCircle,
  Users,
  View,
} from "lucide-react"

import "lucide-react"

export type IconKeys = keyof typeof icons

type IconsType = {
  [key in IconKeys]: React.ElementType
}

type IconProps = React.HTMLAttributes<SVGElement>
type ImgProps = React.ImgHTMLAttributes<HTMLImageElement>

const icons = {
  // Hiraya
  himo: (props: ImgProps) => <img src="/rtap-logo.png" alt="HIMO" {...props} />,

  // Menu
  map: Map,
  profile: UserCircle,
  "sms-blast": Mail,
  billing: Logs,
  "manage-user": Users,
  report: Notebook,
  dashboard: Home,

  // General
  logo: Command,
  sun: SunMedium,
  moon: Moon,
  home: Home,
  users: Users,
  bell: Bell,
  circleUser: CircleUser,
  lineChart: LineChart,
  menu: Menu,
  package: Package,
  package2: Package2,
  search: Search,
  shoppingCart: ShoppingCart,
  mixer: ArrowDownWideNarrow,
  sort: ArrowUpDown,
  download: Download,
  checkCircle: CheckCircle,
  filter: Filter,
  settings: Settings,
  calendar: Calendar,
  loader: LoaderCircle,
  panelsTopLeft: PanelsTopLeft,
  layers: Layers,
  notepad: NotepadText,
  notebook: NotebookText,
  book: Book,
  user: User,
  peso: (props: IconProps) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        className="lucide lucide-philippine-peso"
        {...props}
      >
        <path d="M20 11H4" />
        <path d="M20 7H4" />
        <path d="M7 21V4a1 1 0 0 1 1-1h4a1 1 0 0 1 0 12H7" />
      </svg>
    )
  },
  ellipsis: Ellipsis,
  creditCard: CreditCard,
  key: Key,
  mapPin: MapPin,
  phone: Phone,
  mail: Mail,
  fileScan: FileScan,
  logout: LogOut,
  cmd: Command,
  gauge: Gauge,
  smartphone: Smartphone,
  clock: Clock,

  // Operations
  view: View,
  add: Plus,
  edit: Edit,
  delete: Trash,

  // Chevrons
  up: ChevronUp,
  down: ChevronDown,
  right: ChevronRight,
  left: ChevronLeft,
  updown: ChevronsUpDown,

  // Diagram Icons
  circleMinus: CircleMinus,
  square: Square,
}

export const Icons: IconsType = icons
