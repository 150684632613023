import type { ConsumerConnectionBillingStatusType } from "@/types"
import { useQuery } from "@tanstack/react-query"
import { createFileRoute } from "@tanstack/react-router"

import { getConnectionAndBillingStatus } from "@/lib/api/billing"
import { Loader } from "@/components/loader"
import { MapLegends } from "@/components/map/map-legends"
import { MapRenderer } from "@/components/map/map-renderer"

export const Route = createFileRoute("/_home/map")({
  component: MapPage,
})

function MapPage() {
  const { data: markers, isLoading } = useQuery<
    ConsumerConnectionBillingStatusType[]
  >({
    queryKey: ["markers"],
    queryFn: getConnectionAndBillingStatus,
  })

  return (
    <main>
      {isLoading ? (
        <div className="flex h-screen items-center justify-center">
          <Loader />
        </div>
      ) : (
        <>
          <MapRenderer markers={markers} />
          <div className="fixed bottom-0 right-0 z-[500] p-2 md:p-4">
            <MapLegends />
          </div>
        </>
      )}
    </main>
  )
}
