import { useEffect } from "react"

import "leaflet/dist/leaflet.css"
import "@/styles/leaflet-dark.css"
import "@/styles/leaflet-tooltip.css"

import type {
  ConsumerConnectionBillingStatusType,
  MergedDeviceType,
} from "@/types"
import { Marker } from "@adamscybot/react-leaflet-component-marker"
import { formatDistanceToNow } from "date-fns"
import { MapContainer, Popup, TileLayer, Tooltip, useMap } from "react-leaflet"

import { Icons } from "@/components/icons"

import { MapIcon } from "./map-icon"
import { TrackerIcon } from "./tracker-icon"

interface MapProps {
  position: [number, number]
  markers?: ConsumerConnectionBillingStatusType[]
  trackMarkers?: MergedDeviceType[]
}

function MapUpdater({ position }: { position: [number, number] }) {
  const map = useMap()

  useEffect(() => {
    map.setView(position, map.getZoom())
  }, [map, position])

  return null
}

export function Map({ position, markers, trackMarkers }: MapProps) {
  return (
    <div>
      <MapContainer
        className="h-screen w-full"
        center={position}
        zoom={14}
        minZoom={1}
        maxZoom={18}
        scrollWheelZoom={true}
        doubleClickZoom={false}
        attributionControl={false}
      >
        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapUpdater position={position} />

        {markers &&
          markers.map((marker) => {
            const isSelected =
              marker.lat === position[0] && marker.lon === position[1]
            const connectionStatus = isSelected
              ? "Delinquent"
              : marker.connection_status

            return (
              <Marker
                key={marker.consumer_id}
                position={[marker.lat, marker.lon]}
                icon={<MapIcon connectionStatus={connectionStatus} />}
              >
                <Popup>
                  <div className="flex flex-col gap-1">
                    <div className="flex font-semibold">
                      {marker.consumer_name}
                    </div>
                    <div className="flex">
                      <Icons.user className="mr-2 h-4 w-4" />
                      {connectionStatus}
                    </div>
                    <div className="flex capitalize">
                      <Icons.creditCard className="mr-2 h-4 w-4" />
                      {marker.billing_status.replace("_", " ")}
                    </div>
                  </div>
                </Popup>
              </Marker>
            )
          })}

        {trackMarkers &&
          trackMarkers.map((marker) => {
            const isSelected =
              marker.latitude === position[0] &&
              marker.longitude === position[1]

            return (
              <Marker
                key={marker.device_id}
                position={[marker.latitude, marker.longitude]}
                icon={<TrackerIcon device={marker} selected={!!isSelected} />}
              >
                <Tooltip direction="right" opacity={1} permanent>
                  <div className="flex flex-col gap-1 rounded-lg border bg-background p-2 text-foreground">
                    <div className="flex font-semibold">{marker.full_name}</div>
                    <div className="flex capitalize">
                      <Icons.creditCard className="mr-2 h-4 w-4" />
                      {marker.device_id}
                    </div>
                    <div className="flex capitalize">
                      <Icons.clock className="mr-2 h-4 w-4" />
                      {formatDistanceToNow(new Date(marker.timestamp), {
                        addSuffix: true,
                      })}
                    </div>
                  </div>
                </Tooltip>
              </Marker>
            )
          })}
      </MapContainer>
    </div>
  )
}
