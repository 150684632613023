export const prodEnv = {
  API_URL: "https://himoadmin.hirayatech.ai/api/",
  SESSION_DURATION: 600,
  firebase: {
    apiKey: "AIzaSyA1Zmyo-oi75z1-DyMHxAWNay5a0PUi2XY",
    authDomain: "himo-400a7.firebaseapp.com",
    databaseURL:
      "https://himo-400a7-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "himo-400a7",
    storageBucket: "himo-400a7.firebasestorage.app",
    messagingSenderId: "804298330359",
    appId: "1:804298330359:web:55284fc76f507daf35c3f2",
    measurementId: "G-6TL6R5F5ND",
  },
}
